import { FC, useEffect } from 'react';
import {
    Badge,
    Box,
    Button,
    ColumnLayout,
    Container,
    Header,
    SpaceBetween
} from '@cloudscape-design/components';
import { usegcpProjectCreationFormStore } from '../../../../stores/gcpProjects/newgcpProjectCreationFormStore';
import { useGenericTagsManagementFormStore } from '../../../../stores/tags/genericTagsManagementFormStore';
import { keysToLabel } from '../../../../helper/toCamelCase';

const Review: FC = () => {
    const {
        formValues,
        actions: { setCurrentStep }
    } = usegcpProjectCreationFormStore();

    const { formValues: tagformValues } = useGenericTagsManagementFormStore();

    // Filter out the keys you don't want to display
    const excludedKeys = ['Tag Project', 'Data Classification'];
    const tagKeys = Object.keys(tagformValues).filter(
        (key) => !excludedKeys.includes(keysToLabel(key))
    );

    const gcpProjectType = formValues.gcpProjectType || {}

    return (
        <SpaceBetween direction="vertical" size="l">
            <Header
                variant="h3"
                actions={<Button onClick={() => setCurrentStep(0)}>Edit</Button>}
            >
                Step 1: Project Details
            </Header>
            <Container>
                <ColumnLayout columns={2} variant="text-grid">
                    <div>
                        <Box variant="awsui-key-label">GCP Project Type</Box>
                        <Badge color="blue">{formValues.gcpProjectType}</Badge>
                    </div>
                </ColumnLayout>
            </Container>

            <Header 
                variant='h3' 
                actions={<Button onClick={() => setCurrentStep(1)}>Edit</Button>}>
                Step 2: Project Details
            </Header>

            {gcpProjectType && (
                <Container header={<Header variant="h3">{gcpProjectType.label || 'Project Details'}</Header>}>
                    <ColumnLayout columns={2} variant="text-grid">
                        <div>
                            <Box variant="awsui-key-label">Project Viewer Distribution List</Box>
                            {formValues[`${gcpProjectType.value}ProjectViewerDistributionList`] || 'N/A'}
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Budget Alerts Distribution List</Box>
                            {formValues[`${gcpProjectType.value}BudgetAlertsDistributionList`] || 'N/A'}
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Platform Owner Email</Box>
                            {formValues[`${gcpProjectType.value}PlatformOwnerEmail`] || 'N/A'}
                        </div>
                        <div>
                            <Box variant="awsui-key-label">Project Monthly Budget Cap</Box>
                            {formValues[`${gcpProjectType.value}MonthlyBudgetCap`] || 'N/A'}
                        </div>
                    </ColumnLayout>
                </Container>
            )}

            <Header
                variant="h3"
                actions={<Button onClick={() => setCurrentStep(2)}>Edit</Button>}
            >
                Step 3: Tag Details
            </Header>
            <Container>
                <ColumnLayout columns={2} variant="text-grid">
                    {tagKeys.map((tagkey) => (
                        <div key={tagkey}>
                            <Box variant="awsui-key-label">
                                {keysToLabel(tagkey) === 'W B S Code' ? 'WBS Code' : keysToLabel(tagkey)}
                            </Box>
                            {typeof tagformValues[tagkey] === 'object'
                                ? tagformValues[tagkey]['value']
                                : tagformValues[tagkey] === ''
                                ? 'NA'
                                : tagformValues[tagkey]}
                        </div>
                    ))}
                </ColumnLayout>
            </Container>
        </SpaceBetween>
    );
};

export default Review