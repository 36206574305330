import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Alert,
	Box,
	Button,
	ButtonDropdown,
	Container,
	ContentLayout,
	Grid,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'

import ApprovalDetails from '../../../components/Application/ApprovalDetails'
import ApprovalForm from '../../../components/Application/ApprovalForm'
import RejectionForm from '../../../components/Application/RejectionForm'
import LoadingSpinner from '../../../components/LoadingSpinner'
import CONSTANTS from '../../../constants'
import useSingleApplication from '../../../hooks/query/useSingleApplication'
import RequiredPermissions from '../../../helper/RequiredPermissions'
import CommentsOverview from '../../../components/Application/CommentsOverview'
import RenderApplicationFields from './RenderApplicationFields'
import { Application } from '../../../types/applications'
import useUserPermissions from '../../../hooks/query/useUserPermissions'
import HeaderWithStatusBadges from '../../../components/Common/HeaderWithStatusBadges'
import AccessDetails from '../../../components/Application/AccessDetails'
import ReadGenericTags from '../../../components/ControlledFormInputs/GenericTagsReadGCP'
import EditGenericTags from '../../../components/ControlledFormInputs/GenericTagsEditGCPApplication'

const ApplicationDetails: FC = () => {
	const CURRENT_USER_ID = JSON.parse(localStorage['okta-token-storage'])[
		'idToken'
	]['claims']['email']
	const { id } = useParams() as { id: string }
	const navigate = useNavigate()
	const { data: thisApplication, isLoading } = useSingleApplication(id)
	const thisApplicationID = thisApplication?.applicationID || ''
	const {
		data: userPermissions,
		isLoading: isLoadingPermissions,
		error: permissionsError
	} = useUserPermissions(CURRENT_USER_ID)
	const hasSpecificPermissions = (
		permissions: string[],
		requiredPermissions: string[]
	): boolean => {
		return requiredPermissions.some((requiredPerm) =>
			permissions.includes(requiredPerm)
		)
	}
	const specificPermissions = [
		'applications:*:*',
		'applications:*:approve',
		'*:*:*'
	]
	const hasRequiredPermissions =
		userPermissions &&
		hasSpecificPermissions(userPermissions.permissions, specificPermissions)
	const [showApprovalForm, setShowApprovalForm] = useState(false)
	const [showRejectionForm, setShowRejectionForm] = useState(false)
	const [showButton, setShowButton] = useState(true)

	// const [flag, setFlag] = useState(false)

	if (!thisApplication || isLoading) return <LoadingSpinner />

	if (thisApplication.status === 'ERROR') {
		return (
			<Alert
				statusIconAriaLabel="Error"
				type="error"
				header="An error occurred"
			>
				{thisApplication.details.message}
			</Alert>
		)
	}
	const handleApproveClick = () => {
		setShowApprovalForm(true)
		setShowRejectionForm(false)
		thisApplication.status = 'APPROVED'
	}

	const handleRejectClick = () => {
		setShowRejectionForm(true)
		setShowApprovalForm(false)
		thisApplication.status = 'REJECTED'
	}

	const applicationVersionedObject = getKeyValueApplication(thisApplication)

	function handleShowAccessDetails(): void {
		setShowButton(!showButton)
	}

	console.log(thisApplication)
	let isAllTagsApproved = false
	if (thisApplication.tags !== undefined) {
		isAllTagsApproved = true
		let applicationTags = thisApplication.tags
		let applicationTagsApprovalStatuses = applicationTags.approvalStatus
		for (const tagStatus of applicationTagsApprovalStatuses) {
			if (tagStatus.status !== 1) {
				isAllTagsApproved = false
			}
		}
	}

	return (
		<ContentLayout
			header={
				<HeaderWithStatusBadges
					headerName={thisApplication?.details.applicationName}
					status={thisApplication?.status}
				/>
			}
		>
			<Grid
				gridDefinition={[
					{ colspan: { default: 3, xxs: 9 } },
					{ colspan: { default: 9, xxs: 3 } }
				]}
			>
				<SpaceBetween direction="vertical" size="l">
					<RenderApplicationFields Application={applicationVersionedObject} />

					{!hasRequiredPermissions && (thisApplication.status === 'PENDING_APPROVAL') && (
						<RequiredPermissions requiredPermissions={CONSTANTS.APPLICATION_REQUESTOR}>
							<Container header={<Header variant='h3'>Tag Details</Header>}>
								{thisApplication.tagsID && (
									<ReadGenericTags tagsId={thisApplication.tagsID}/>
								)}
							</Container>
						</RequiredPermissions>
					)}

					{(thisApplication.status === 'APPROVED' || thisApplication.status === 'REJECTED') && (
						<RequiredPermissions requiredPermissions={CONSTANTS.APPLICATION_REQUESTOR}>
							<Container header={<Header variant='h3'>Tag Details</Header>}>
								{thisApplication.tagsID && (
									<ReadGenericTags tagsId={thisApplication.tagsID}/>
								)}
							</Container>
						</RequiredPermissions>
					)}

					{thisApplication.status === 'PENDING_APPROVAL' && hasRequiredPermissions && (
						<RequiredPermissions requiredPermissions={CONSTANTS.APPLICATION_APPROVER}>
							<Container header={<Header variant='h3'>Tag Details</Header>}>
								{thisApplication.tagsID && (
									<EditGenericTags tagsId={thisApplication.tagsID}/>
								)}
							</Container>
						</RequiredPermissions>
					)}

					{thisApplication.status === 'PENDING_APPROVAL' &&
						CURRENT_USER_ID !== thisApplication.requestorID && (
							<RequiredPermissions
								requiredPermissions={CONSTANTS.APPLICATION_APPROVER}
							>
								<Box float="left">
								<ButtonDropdown
									items={isAllTagsApproved ? [
										{ text: 'To Approve', id: 'to-approve' },
										{ text: 'To Reject', id: 'to-reject' }
									] : [
										{ text: 'To Reject', id: 'to-reject' }
									]}
									onItemClick={({ detail }) => {
										if (detail.id === 'to-approve') {
											setShowApprovalForm(true)
											setShowRejectionForm(false)
										}

										if (detail.id === 'to-reject') {
											setShowApprovalForm(false)
											setShowRejectionForm(true)
										}
									}} variant='primary'>
									Action
								</ButtonDropdown>
								</Box>
							</RequiredPermissions>
						)}
					{showApprovalForm && (
						<ApprovalForm
							thisApplicationID={thisApplicationID}
							shouldShowApprovalForm={setShowApprovalForm}
						/>
					)}

					{showRejectionForm && (
						<RejectionForm
							thisApplicationID={thisApplicationID}
							shouldShowRejectionForm={setShowRejectionForm}
						/>
					)}

					{thisApplication.status === 'APPROVED' && (
						<RequiredPermissions
							requiredPermissions={CONSTANTS.APPLICATION_APPROVER}
						>
							<ApprovalDetails approvalDetails={thisApplication!} />
						</RequiredPermissions>
					)}

					{thisApplication.status === 'APPROVED' &&
						CURRENT_USER_ID !== thisApplication.requestorID &&
						showButton && (
							<RequiredPermissions
								requiredPermissions={CONSTANTS.APPLICATION_APPROVER}
							>
								<Button variant="primary" onClick={handleShowAccessDetails}>
									View Access Details
								</Button>
							</RequiredPermissions>
						)}

					{!showButton && (
						<AccessDetails
							env={thisApplication?.details.environment.value}
							platformName={thisApplication?.details.platformName.label}
							appName={thisApplication?.details.applicationName}
						/>
					)}
				</SpaceBetween>

				<SpaceBetween direction="vertical" size="l">
					<Container header={<Header variant="h3">Comment History</Header>}>
						<CommentsOverview comments={thisApplication.comments} />
					</Container>
				</SpaceBetween>
			</Grid>
		</ContentLayout>
	)
}

const getKeyValueApplication = (application: any) => {
	console.log(application, 'application')
	const baseEntries = [
		{
			label: 'Requestor',
			value: application?.requestorID,
			version: getVersionStr(application?.version, 'applicationName')
		},
		{
			label: 'Platform Name',
			value: application?.details.platformName.label,
			version: getVersionStr(application?.version, 'platformName')
		},
		{
			label: 'Application Name',
			value: application?.details.applicationName,
			version: getVersionStr(application?.version, 'applicationName')
		},
		{
			label: 'Environment',
			value: application?.details.environment,
			version: getVersionStr(application?.version, 'environment')
		},
		{
			label: 'Region',
			value: 'Singapore (asia-southeast1)',
			version: getVersionStr(application?.version, 'environment')
		},
		{
			label: 'Application Viewers',
			value: application.details.applicationViewer.join(', '),
			version: getVersionStr(application?.version, 'environment')
		},
		// {
		// 	label: 'Platform Cluster Name',
		// 	value: application.wkldClusterName || 'dummy',
		// 	version: getVersionStr(application?.version, 'environment')
		// },
		{
			label: 'Application Alias',
			value: application.details.applicationAlias,
			version: getVersionStr(application?.version, 'environment')
		},
		{
			label: 'WBS Code',
			value: application.details.wbsCode,
			version: getVersionStr(application?.version, 'environment')
		},
		{
			label: 'Cost Center',
			value: application.details.costCenter,
			version: getVersionStr(application?.version, 'environment')
		}
	]

	const additionalEntries = application?.details.additionalEnvName
		? [
				{
					label: 'Source Env Name',
					value: application.details.sourceEnvName,
					version: getVersionStr(application?.version, 'environment')
				},
				{
					label: 'Additional Env Name',
					value: application.details.additionalEnvName,
					version: getVersionStr(application?.version, 'environment')
				}
		  ]
		: []

	return [...baseEntries, ...additionalEntries]
}

const getVersionStr = (version: Array<any> | undefined, key: string) => {
	let versionItems: string[] = []
	version?.forEach((element) => {
		if (!element[key]) return
		if (key === 'region' && element.region) {
			versionItems.unshift(`v${element.versionID}: ${element.region[0].label}`)
		} else if (key === 'platformName' && element.platformName) {
			versionItems.unshift(
				`v${element.versionID}: ${element.platformName.value}`
			)
		} else {
			versionItems.unshift(`v${element.versionID}: ${element[key]}`)
		}
	})
	return versionItems
}

export default ApplicationDetails
