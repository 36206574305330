import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSingleProject from "../../../../hooks/query/useSingleProject";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import { ProjectResource } from "../../../../types/projectResource";
import { StatusIndicator, Link, Button } from "@cloudscape-design/components";
import { Table } from '@aws-northstar/ui/components'
import useProjectResources from "../../../../hooks/query/useProjectResources";


const SslCerts: FC = () => {
  const { id: projectId } = useParams() as { id: string }

  const { data: project, isLoading: isProjectLoading } = useSingleProject(projectId)

  const { data: projectResources, isLoading } = useProjectResources(projectId)

  const navigate = useNavigate()

  if (isLoading || isProjectLoading) {
    return <LoadingSpinner/>
  }

  if (project === undefined || (projectResources === undefined)) {
    return (
      <>
        <h1>Project Resource Not Found</h1>
      </>
    )
  }

  const columnDefinitions = [
    {
      id: 'sslCertID',
      width: 100,
      header: 'Certificate Name',
      cell: (data: ProjectResource) => (
        <Link onFollow={() => navigate(`/projects/${projectId}/sslCerts/${data.projectResourceID}`)}>{data.details.domainName}.{data.details.hostedZoneName}</Link>
      )
    },
    {
      id: 'data.certType',
      width: 100,
      header: 'Certificate Type',
      cell: (data: ProjectResource) => data.details.certType
    },
    {
      id: 'projectName',
      width: 100,
      header: 'AWS Project Name',
      cell: (data: ProjectResource) => (
        <Link onFollow={() => navigate(`/projects/${data.projectID}`)}>{data.details.projectName}</Link>
      )
    },
    {
      id: 'requestor',
      width: 100,
      header: 'Requested By',
      cell: (data: ProjectResource) => data.requestorID
    },
    {
      id: 'creationTime',
      width: 100,
      header: 'Created At',
      cell: (data: ProjectResource) => {
        if (data.creationTime) {
          const date = new Date(data.creationTime)
          const year = date.getFullYear()
          const month = (date.getMonth() + 1).toString().padStart(2, '0')
          const day = date.getDate().toString().padStart(2, '0')

          const hour = date.getUTCHours().toString().padStart(2, '0')
          const minutes = date.getUTCMinutes()

          return `${day}-${month}-${year} ${hour}:${minutes}`
        }
        return ''
      }
    },
    {
      id: 'approverID',
      width: 100,
      header: 'Approved By (AWS)',
      cell: (data: ProjectResource) => data.approval?.approverID || '-'
    },
    {
      id: 'isdpApproverID',
      width: 100,
      header: 'Approved By (ISDP)',
      cell: (data: ProjectResource) => {
        if (data.approvalStatus[0].approverID !== undefined && data.approvalStatus[0].approverID !== "" && data.approvalStatus[0].status !== 0) {
          return data.approvalStatus[0].approverID
        }
        else {
          return '-'
        }
      }
    },
    {
      id: 'status',
      width: 100,
      header: 'Status',
      cell: (data: ProjectResource) => {
        const status = data.status
        switch (status) {
          case 'PENDING_APPROVAL':
            return (
              <StatusIndicator type="in-progress" colorOverride="blue">
                Pending Approval
              </StatusIndicator>
            )
          case 'APPROVED':
            return <StatusIndicator type="success">Approved</StatusIndicator>
          case 'REJECTED':
            return <StatusIndicator type="error">Rejected</StatusIndicator>
          default:
            return null
        }
      }
    }
  ]

  const tableActions = (
    <div style={{ display: 'flex', gap: '10px' }}>
      <Button variant="primary" onClick={() => navigate(`/projects/${projectId}/sslCerts/new`)}>
        New SSL Certificate
      </Button>
    </div>
  )


  return (
    <Table
      header={`SSL Certificates for ${[project.details.projectName]}`}
      columnDefinitions={columnDefinitions}
      items={projectResources || []}
      actions={tableActions}
      disableRowSelect
      sortingDisabled/>
  )
}

export default SslCerts